import React, { useState } from 'react';
import { Steps } from 'primereact/steps';
import MapSelection from './MapSelection';
import DataUpload from './DataUpload';
import OccupancyHours from './OccupancyHours';
import EnergyGraph from "./charts/EnergyGraph";
import {Button} from "primereact/button";
import {
  aggregateData,
  interpolateToHalfHourly,
  mergeTimeSeries,
  Reading,
  transformToOccupancyHoursUsingMean
} from "../model/util";
import XYPlot from "./charts/XYChart";
import {DataPoint, WeatherStation} from "./interfaces/interfaces";
import {processData, trainAndPredict} from "../model/tensorflow/util";

const StepComponent: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState(null); // For storing map selection
  const [csvData, setCsvData] = useState([]); // For storing uploaded CSV data
  const [occupancyData, setOccupancyData] = useState({}); // For storing occupancy data
  const [combinedData, setCombinedData] = useState<Array<DataPoint>>([]);

  const [modelData, setModelData] = useState<Array<DataPoint>>([]);

  const [selectedStation, setSelectedStation] = useState({USAF: '', WBAN: '', STATION_NAME: ''} as WeatherStation);
  // @ts-ignore
  const items = [
    {label: 'Location '+ selectedStation.STATION_NAME, command: () => { setActiveIndex(0); }},
    {label: 'Upload Data', command: () => { setActiveIndex(1); }},
    {label: 'Occupancy', command: () => { setActiveIndex(2); }},
    {label: 'Energy Waste', command: () => { setActiveIndex(3); }}
  ];
  const [weatherData, setWeatherData] = useState<Array<Reading>>([]);

  const handleStationSelect = async (station:WeatherStation) => {
    const currentYear = new Date().getFullYear();
    const years = [currentYear - 3, currentYear - 2,currentYear - 1, currentYear ];

    setSelectedStation(station);
    const promises = years.map(year =>
      fetch(`https://insightdev.kwiqly.com/weather/${year}/${station.USAF}/${station.WBAN}`)
        .then(response => {
          if (response.ok) { // Check if the status code is 200
            return response.text();
          } else {
            return ''
          }
        })
    );

    try {
      const dataArrays = await Promise.all(promises);
      const aggregatedData = aggregateData(dataArrays);

        // @ts-ignore
      setWeatherData(aggregatedData);


    } catch (error) {
      console.error("Failed to fetch weather data:", error);
    }
  };
  const handleLocationSelect = (location: React.SetStateAction<any>) => {
    setSelectedLocation(location);
  };


  const handleCsvDataLoaded = (data: React.SetStateAction<any>, hourlyAverages:React.SetStateAction<any>):void => {
    setCsvData(data)

    const interpolatedData = interpolateToHalfHourly(weatherData)

    const mergedData = mergeTimeSeries(data, interpolatedData)
    setCombinedData(mergedData)
    const processedData = processData(mergedData)
    console.log(processedData)
    console.log(mergedData)
    trainAndPredict(processedData).then((result) => {
      setModelData(result as DataPoint[])
    } )
    setOccupancyData(transformToOccupancyHoursUsingMean(hourlyAverages))
  };

  const handleOccupancyChange = (data: React.SetStateAction<{}>) => {
    setOccupancyData(data);
  };

  const onNext = () => {
    if (activeIndex < items.length - 1) {
      transitionToStep(activeIndex + 1);
    }
  }

  const onBack = () => {
    if (activeIndex > 0) {
      transitionToStep(activeIndex - 1);
    }
  }
  const appData= {location:selectedLocation, data:csvData, occupancy:occupancyData}
  const getStepContent = (index: number) => {
    switch (index) {
      case 0:
        return <MapSelection onLocationSelected={handleLocationSelect} onNext={()=>{}}  onStationSelected={handleStationSelect}/>;
      case 1:
        return <DataUpload onDataLoaded={handleCsvDataLoaded} combinedData={combinedData}/>;
      case 2:
        return <OccupancyHours onChange={handleOccupancyChange} initialOccupancy={occupancyData}/>;
      case 3:
        return <XYPlot data={modelData} />;
      default:
        return <div>Unknown Step</div>;
    }
  };

  const transitionToStep=(index: number)=> {
    switch (index) {
      case 0:
        setActiveIndex(0);break
      case 1:
        if(selectedLocation){
          setActiveIndex(1);
        }
        break
      case 2:
        if(csvData.length>0){
          setActiveIndex(2);
        }
        break
      case 3:
        setActiveIndex(3);break
      default:
        return
    }
  }
  return (
    <div className={'stepContainer'}>
      <Steps model={items} activeIndex={activeIndex} onSelect={(e) => transitionToStep(e.index)} readOnly={false}/>
      <div className={'step'}>
        <div className={'stepButtonBar'}>
          {activeIndex > 0 &&
            <Button label="Back" className="p-button-raised p-button-secondary" onClick={onBack}
                    style={{marginTop: '10px', float: 'right', marginRight: '10px'}}/>
          }
          {activeIndex < items.length - 1 &&
            <Button label="Next" className="p-button-raised p-button-success" onClick={onNext}
                    style={{marginTop: '10px', float: 'right'}}/>
          }

        </div>
        {getStepContent(activeIndex)}

      </div>
    </div>
  );
};

export default StepComponent;
