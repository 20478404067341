import {getHourOfWeek, getISOWeekNumber} from "./model/util";

export function downloadCSV(csvString:string, filename:string) {
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
export function convertToCSV(data: any[]) {

  const headers = "timestamp, date, time, day_of_week, month, calenderweek, energy_onsumption,outside_temperature,hourOfWeek\n";
  const csvContent = data.reduce((acc, row) => {
    const hourOfWeek = getHourOfWeek(row.DateTime);
    const minuteOfDay= row.DateTime.toISOString().substring(11,16).replace(':','');
    const month=row.DateTime.getMonth();
    const week=getISOWeekNumber(row.DateTime);
    const dayOfWeek=row.DateTime.getDay();
    return acc + `${row.DateTime.toISOString()},${row.DateTime.toISOString().substring(0,10)},${minuteOfDay},${dayOfWeek},${month},${week},${row.consumption},${row.oat},${hourOfWeek}\n`;
  }, headers);
  return csvContent;
}
