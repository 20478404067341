import React, { useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import moment from 'moment';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import WeekProfileChart from "./charts/WeekProfileChart";
import {calculateWeekProfile, CombinedTimeSeries, detectDateFormat, formatData} from "../model/util";
import {TabPanel, TabView} from "primereact/tabview";
import TimeSeries3DPlot from "./charts/TimeSeries3DPlot";
import {DateMetrics} from "./interfaces/interfaces";

interface DataUploadProps {
  onDataLoaded: (data: any[], hourlyAverages: any) => void;
  combinedData: CombinedTimeSeries
}

const DataUpload: React.FC<DataUploadProps> = ({ onDataLoaded, combinedData }) => {
  const [previewData, setPreviewData] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [dateMetrics, setDateMetrics] = useState<DateMetrics | null>(null);
  const [hourlyAverages, setHourlyAverages] = useState<any>({});
  const handleFileSelect = (event: any) => {
    const file = event.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target?.result as string;
        const data = parseCsv(text);
        const detectedFormat = detectDateFormat(data);

        const formattedData = formatData(data, detectedFormat);
        const weekProfile=calculateWeekProfile(formattedData)

        setHourlyAverages(weekProfile);
        onDataLoaded(formattedData, weekProfile );
        preparePreviewData(formattedData);
        calculateDateMetrics(formattedData);
      };
      reader.readAsText(file);
    }
  };

  const parseCsv = (csvText: string): any[] => {
    const lines = csvText.split('\n').filter(line => line.trim() !== ''); // Filter out empty lines
    return lines.map((line, lineIndex) => {
      const data = line.split(',').map(d => d.trim());

      const headers = ['Date', ...data.slice(1).map((_, idx) => `Reading${idx + 1}`)];
      return headers.reduce((acc, header, index) => {
        // @ts-ignore
        acc[header] = index < data.length ? data[index] : '';
        return acc;
      }, {});
    });
  };

  const calculateDateMetrics = (data: any[]) => {

    const dates = data.map(item => moment(item.DateTime, 'YYYY-MM-DD'));

    if (dates.length === 0) return;

    const sortedDates = dates.sort((a, b) => a.valueOf() - b.valueOf());

    const startDate = sortedDates[0].format('YYYY-MM-DD');
    const endDate = sortedDates[sortedDates.length - 1].format('YYYY-MM-DD');
    const totalDays = sortedDates[sortedDates.length - 1].diff(sortedDates[0], 'days') + 1; // Plus one to include both start and end dates
    const readingsPerDay = data.length / totalDays;

    setDateMetrics({
      startDate: startDate,
      endDate: endDate,
      totalDays: totalDays,
      readingsPerDay: readingsPerDay
    });
  };

  const preparePreviewData = (data: any[]) => {

    setPreviewData(data);
    setColumns(data.length > 0 ? Object.keys(data[0]).map(col => ({ field: col, header: col })) : []);
  };

  return (
    <div className={'dataupload'}>
      <FileUpload name="demo[]" url="./upload" customUpload uploadHandler={handleFileSelect}
                  accept=".csv" maxFileSize={10000000000} mode="basic" auto chooseLabel="Select CSV File" />
      <TabView className={'tabview'}>

          <TabPanel header="Hourly Averages">
          <WeekProfileChart hourlyAverages={hourlyAverages} />
            </TabPanel>
          <TabPanel header="Preview Data">
          <TimeSeries3DPlot data={combinedData} />
          </TabPanel>


        <TabPanel header="Date Metrics">

          <p>Start Date: {dateMetrics?.startDate}</p>
          <p>End Date: {dateMetrics?.endDate}</p>
          <p>Total Days: {dateMetrics?.totalDays}</p>
          <p>Readings per Day: {dateMetrics?.readingsPerDay.toFixed(2)}</p>
        </TabPanel>
      </TabView>
    </div>
  );
};

export default DataUpload;
