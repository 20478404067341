import React from 'react';
import Plot from 'react-plotly.js';
import { Data } from 'plotly.js';

import {CombinedTimeSeries, getHourOfWeek} from "../../model/util";
import {convertToCSV, downloadCSV} from "../../download";

interface TimeSeries3DPlotProps {
  data: CombinedTimeSeries;
}

const TimeSeries3DPlot: React.FC<TimeSeries3DPlotProps> = ({ data }) => {

  const handleDownload = () => {
    const csvData = convertToCSV(data);
    downloadCSV(csvData, 'combined_time_series.csv');
  };
  const trace: Data = {
    z: data.map(d => d.consumption), // Consumption
    x: data.map(d => d.oat), // OAT
    y: data.map(d => getHourOfWeek(d.timestamp)), // Hour of week
    mode: 'markers',
    type: 'scatter3d',
    marker: {
      size: 4,
      color: data.map(d => getHourOfWeek(d.timestamp)), // Optional: color markers by hour of week
      colorscale: 'Viridis',
      opacity: 0.8
    }
  };

  // @ts-ignore
  return (
    <div><Plot
      data={[trace]}
      layout={{
        width: 700,
        height: 700,
        title: '3D Scatter Plot: Consumption vs OAT vs Hour of Week',
        scene: {
          zaxis: {title: 'Consumption'},
          xaxis: {title: 'OAT'},
          yaxis: {title: 'Hour of Week'},
          camera: {
            eye: {x: 1.25, y: 1.25, z: 1.25}
          }
        }
      }}
    />
  <button onClick={handleDownload} style={{margin: '20px', padding: '10px'}}>
    Download Data as CSV
  </button></div>
)
  ;
};

export default TimeSeries3DPlot;
